import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import genderService from '@/services/util/genderService.js';
import titleService from '@/services/util/titleService.js';
import WarningDialog from '@/components/core/WarningDialog.vue';
const DEFAULT_CONTACT_FIELDS = {
  key: {},
  titel: '',
  vorname: '',
  nachname: '',
  dw: '',
  geschlecht: 0,
  funktionen: [],
  email: '',
  geburtsdatum: '',
  tel_mobil: '',
  aussendung: false,
  active: false,
  anmerkung: '',
  hauptansprechpartner: false,
  tel2: '',
  fax: '',
  abteilungen: []
};
export default {
  components: {
    WarningDialog
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      // form
      formValid: false,
      errorMessageTel: [],
      errorMessageTel2: [],
      errorMessageEmail: [],
      titleSuggestions: [],
      titel: null,
      showInactiveContactPersons: false,
      menu: false,
      activeContact: DEFAULT_CONTACT_FIELDS,
      deleteContactDialog: false,
      newContact: false,
      oldContact: {},
      showContact: false,
      loading: true,
      dialog: false,
      dialogDelete: false,
      search: '',
      sortDesc: true,
      editorContent: '',
      addedContact: {},
      updatedContact: {},
      deleteContact: {},
      warningDialog: false,
      warnMessage: 'Wollen Sie diesen Ansprechpartner löschen?',
      actions: [],
      colsPerButton: 0,
      additionalData: [],
      expanded: [],
      singleExpand: false,
      dialogInputMethods: ['Manuell', 'E-Mail', 'Telefon'],
      headers: [{
        text: 'Vorname',
        align: 'start',
        value: 'vorname'
      }, {
        text: 'Nachname',
        value: 'nachname'
      }, {
        text: 'Abteilung',
        value: 'abteilungen'
      }, {
        text: 'Funktion',
        value: 'funktionen'
      }, {
        text: 'E-Mail',
        value: 'email'
      }, {
        text: 'Telefon mobil',
        value: 'tel_mobil'
      }, {
        text: 'Telefon 2',
        value: 'tel2'
      }, {
        text: 'Durchwahl',
        value: 'dw'
      }, {
        text: 'Haupt',
        value: 'hauptansprechpartner'
      }, {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12em'
      }],
      // birthDataValidationRule: value => {
      //   const pattern = /^\d{4}-\d{2}-\d{2}$/
      //   return pattern.test(value) || 'Ungültiges Geburtsdatum'
      // }

      functionSearchInput: null,
      departmentSearchInput: null,
      validData: {
        validTel: false,
        validTel2: false,
        validEmail: false
      }
    };
  },
  computed: {
    ...mapState({
      contacts: state => state.crmContacts.contacts,
      genders: state => state.gender.genders,
      functions: state => state.crmContacts.functions,
      departments: state => state.crmContacts.departments,
      cardTitle() {
        if (this.activeContact.nachname === '' && this.activeContact.vorname === '')
          // prints out '' if variable in template literal is null
          return 'Neue Kontaktperson';else {
          let returnString = '';
          if (this.activeContact.titel !== '') returnString = returnString + this.activeContact.titel + ' ';
          returnString = returnString + this.activeContact.vorname + ' ' + this.activeContact.nachname;
          return returnString;
        }
      }
    }),
    filteredContacts() {
      if (this.showInactiveContactPersons) {
        return this.contacts;
      }
      return this.contacts.filter(c => c.active);
    }
  },
  methods: {
    closeAddingForm() {
      this.errorMessageTel = null;
      this.errorMessageTel2 = null;
      this.errorMessageEmail = null;
    },
    validatePhoneNumber(telNumber) {
      const pattern = /^(?=.{8,})\+?\d[\d\s]*$/;
      if (telNumber === '') {
        this.validData.validTel = true; //wenn es leer ist, kann man trotzdem einen Kontakt hinzufügen
        return true;
      }
      if (pattern.test(telNumber)) {
        this.errorMessageTel = [];
        this.validData.validTel = true;
        return true;
      } else {
        this.validData.validTel = false;
        this.errorMessageTel = ['Gültige Telefonnummer eingeben'];
        return false;
      }
    },
    validatePhoneNumber2(telNumber) {
      const pattern = /^(?=.{8,})\+?\d[\d\s]*$/;
      if (telNumber === '') {
        this.validData.validTel2 = true;
        return true;
      }
      if (pattern.test(telNumber)) {
        this.errorMessageTel2 = [];
        this.validData.validTel2 = true;
        return true;
      } else {
        this.validData.validTel2 = false;
        this.errorMessageTel2 = ['Gültige Telefonnummer eingeben'];
        return false;
      }
    },
    validateEmail(email) {
      const pattern = /@/;
      if (email.length == 0) {
        this.validData.validEmail = true;
        return true;
      }
      if (pattern.test(email)) {
        this.validData.validEmail = true;
        return true;
      } else {
        this.validData.validEmail = false;
        return 'Gültige Email-Adresse eingeben';
      }

      // const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // if (email === '') {
      //   this.validData.validEmail = true
      //   return true
      // }

      // if (pattern.test(email)) {
      //   this.validData.validEmail = true
      //   return true
      // } else {
      //   this.validData.validEmail = false
      //   this.errorMessageEmail = ['Gültige Email-Adresse eingeben']
      //   return false
      // }
    },
    isFormValid() {
      return this.validData.validTel && this.validData.validTel2 && this.validData.validEmail;
    },
    init() {
      this.loadContacts();
      this.getFunktionen();
      this.loadDepartments();
    },
    deleteItemConfirm() {
      this.remove(this.deleteContactPerson);
      this.warningDialog = false;
      this.deleteContactPerson = null;
    },
    open(warnMessage, actions, additionalData = []) {
      this.warnMessage = warnMessage;
      this.actions = actions;
      this.warningDialog = true;
      this.colsPerButton = Math.floor(12 / actions.length);
      this.additionalData = additionalData;
    },
    warningActionPerformed(action) {
      this.$emit('warningActionPerformed', action);
      this.warningDialog = false;
    },
    async loadContacts() {
      await this.$store.dispatch('loadContacts', {
        lfdnr: this.lfdnr
      }).then(() => this.loading = false);
    },
    autofillTitle() {
      titleService.searchTitle(this.titel).then(res => {
        this.titleSuggestions = res.data;
      }).catch(error => {
        console.error('Error fetching title suggestions:', error);
      });
    },
    getFunktionen() {
      this.$store.dispatch('getFunktionen');
    },
    loadDepartments() {
      this.$store.dispatch('getDepartments');
    },
    cancel() {
      this.activeContact = {
        ...this.oldContact
      };
    },
    close() {
      this.showContact = false;
    },
    autofillGender() {
      genderService.predictGenderBasedOnFirstName(this.activeContact.vorname).then(res => {
        if (res.status == 200) {
          this.activeContact.geschlecht = res.data;
        }
      });
    },
    async performDefaultGenderActionIfGenderIsNull() {
      var _this$activeContact$g;
      //Wenn Benutzer kein Geschlecht angibt, dann wird geschlecht auf "ohne" gesetzt
      let id = (_this$activeContact$g = this.activeContact.geschlecht) === null || _this$activeContact$g === void 0 || (_this$activeContact$g = _this$activeContact$g.key) === null || _this$activeContact$g === void 0 ? void 0 : _this$activeContact$g.lfdnr;
      if (id === undefined) {
        id = this.activeContact.geschlecht;
      }
      let result = this.genders.find(obj => obj.key.lfdnr == id);
      this.activeContact.geschlecht = result;
    },
    showCreateContactPersonMenu() {
      this.activeContact = Object.assign({}, DEFAULT_CONTACT_FIELDS);
      console.warn(this.activeContact);
      this.show();
    },
    createContactPerson() {
      this.performDefaultGenderActionIfGenderIsNull();
      if (this.activeContact.active == false) {
        this.activeContact.active = 0;
      } else {
        this.activeContact.active = 1;
      }
      this.addedContact = {
        key: {
          firma: 1,
          kdnr: this.lfdnr,
          lfdnr: 1
        },
        titel: this.activeContact.titel,
        vorname: this.activeContact.vorname,
        nachname: this.activeContact.nachname,
        dw: this.activeContact.dw,
        geschlecht: this.activeContact.geschlecht.key.lfdnr,
        funktionen: this.activeContact.funktionen,
        email: this.activeContact.email,
        geburtsdatum: this.activeContact.geburtsdatum,
        tel_mobil: this.activeContact.tel_mobil,
        aussendung: this.activeContact.aussendung,
        active: this.activeContact.active,
        anmerkung: this.activeContact.anmerkung,
        hauptansprechpartner: this.activeContact.hauptansprechpartner,
        tel2: this.activeContact.tel2,
        fax: this.activeContact.fax,
        abteilungen: this.activeContact.abteilungen
      };
      this.$store.dispatch('saveContact', {
        lfdnr: this.lfdnr,
        wf_cid: this.wf_cid,
        contact: this.addedContact
      }).then(() => {
        this.loading = false;
      });
      this.showContact = false;
    },
    saveExistingContactPerson() {
      this.performDefaultGenderActionIfGenderIsNull();
      this.updatedContact = {
        key: this.activeContact.key,
        titel: this.activeContact.titel,
        vorname: this.activeContact.vorname,
        nachname: this.activeContact.nachname,
        dw: this.activeContact.dw,
        geschlecht: this.activeContact.geschlecht.key.lfdnr,
        funktionen: this.activeContact.funktionen,
        email: this.activeContact.email,
        geburtsdatum: this.activeContact.geburtsdatum,
        tel_mobil: this.activeContact.tel_mobil,
        aussendung: this.activeContact.aussendung,
        active: this.activeContact.active == true ? 1 : 0,
        anmerkung: this.activeContact.anmerkung,
        hauptansprechpartner: this.activeContact.hauptansprechpartner,
        tel2: this.activeContact.tel2,
        fax: this.activeContact.fax,
        abteilungen: this.activeContact.abteilungen
      };
      this.$store.dispatch('updateContact', {
        lfdnr: this.lfdnr,
        wf_cid: this.wf_cid,
        contact: this.updatedContact
      }).then(() => {
        this.loading = false;
      });
      this.showContact = false;
    },
    show(item) {
      this.titleSuggestions = [];
      if (item == undefined) {
        this.activeContact.active = true;
        this.newContact = true;
      } else {
        var _this$activeContact$d;
        this.newContact = false;
        this.activeContact = {
          ...item
        };
        // Date fix in showing (only displaying date in YYYY-MM-DD format)
        if (this.activeContact.geburtsdatum != null) {
          this.activeContact.geburtsdatum = new Date(this.activeContact.geburtsdatum).toISOString().split('T')[0];
        }

        //get Gender of active Contact

        if (this.titleSuggestions.length === 0 && this.activeContact.titel !== '') {
          this.titleSuggestions.push(this.activeContact.titel);
        }
        this.activeContact.department = (_this$activeContact$d = this.activeContact.department) === null || _this$activeContact$d === void 0 ? void 0 : _this$activeContact$d.department;

        //Kontakt vor Änderungen
        this.oldContact = Object.assign({}, this.activeContact);
      }
      this.performDefaultGenderActionIfGenderIsNull();
      this.showContact = true;
    },
    remove(item) {
      this.$store.dispatch('deleteContact', {
        lfdnr: this.lfdnr,
        key: item.key
      }).then(() => {
        this.loadContacts();
        this.deleteContactDialog = false;
      });
    },
    functionComboBoxFilter(item, queryText, itemText) {
      if (item.header) return false;
      const text = itemText == null ? '' : itemText;
      const query = queryText == null ? '' : queryText;
      if (query.startsWith('*')) {
        const editedQuery = query.toString().replace('*', '');
        return text.toString().toLowerCase().indexOf(editedQuery.toString().toLowerCase()) > -1;
      } else {
        return text.toString().toLowerCase().startsWith(query.toString().toLowerCase());
      }
    },
    formatContactPersonActive(item) {
      if (item != null && false == item.active) {
        return 'inactive-row font-italic disabled';
      }
    }
  },
  watch: {
    lfdnr() {
      this.init();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  mounted() {
    this.init();
  }
};