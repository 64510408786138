import "core-js/modules/es.array.push.js";
import geocoding from '@/services/geocoding/openstreetmap.js';
import InteractionsTable from '@/components/crm/addresses/InteractionsTable.vue';
import ContactPersonsV2 from '@/components/crm/addresses/ContactPersonsV2.vue';
import CustomerGroups from '@/components/crm/addresses/CustomerGroups.vue';
import ContactDataDialog from '@/components/crm/addresses/ContactDataDialog.vue';
import AddressPicturesDialog from '@/components/crm/addresses/AddressPicturesDialog.vue';
import CRMArticleMovements from '@/components/crm//addresses/CRMArticleMovements.vue';
import DocumentsTable from '@/components/crm/addresses/DocumentsTable.vue';
import AddressTypeChip from '@/components/crm/addresses/AddressTypeChip.vue';
import addressService from '@/services/crm/addressService.js';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import ArticleTypesEK from '@/components/statistics/EkRevenueStatistics/ArticleTypes.vue';
import Prescription from '@/components/statistics/PrescriptionStatistics/Prescription.vue';
import KKStatistics from '@/components/statistics/KK_Statistics/KK_Statistics.vue';
import L from 'leaflet';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import { mapState } from 'vuex';
import PatientinformationTab from '@/components/crm/addresses/editAddress/PatientInformationTab.vue';
import EmployeeInformationTab from '@/components/crm/addresses/editAddress/EmployeeInformationTab.vue';
import DoctorInformationTab from '@/components/crm/addresses/editAddress/DoctorInformationTab.vue';
import CompanyInformationTab from '@/components/crm/addresses/editAddress/CompanyInformationTab.vue';
import SupplierInformationTab from '@/components/crm/addresses/editAddress/SupplierInformationTab.vue';
import HealthInsuranceCompanyInformationTab from '@/components/crm/addresses/editAddress/HealthInsuranceCompanyInformationTab.vue';
import VK from '@/components/statistics/vkCustomerRevenue/ArticleTypes.vue';
delete L.Icon.Default.prototype._getIconUrl;
export default {
  components: {
    PatientinformationTab,
    EmployeeInformationTab,
    DoctorInformationTab,
    CompanyInformationTab,
    SupplierInformationTab,
    HealthInsuranceCompanyInformationTab,
    ContactPersonsV2,
    AddressPicturesDialog,
    CustomerGroups,
    InteractionsTable,
    CRMArticleMovements,
    DocumentsTable,
    AddressTypeChip,
    VK,
    ArticleTypesEK,
    Prescription,
    KKStatistics,
    ContactDataDialog,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    ErrorDialog
  },
  data: () => ({
    addressExists: 'loading',
    tab: null,
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: [],
      loading: true,
      address: {},
      description: ''
    },
    dialogOpen: false,
    dialogTab: null,
    editContactData: false,
    addressTypeName: [],
    adressTypeDetailedName: [],
    showMap: true,
    mapsAddressObject: {
      lat: 0,
      lng: 0
    },
    loading: true,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    mapOptions: {
      zoomSnap: 0.5
    },
    showAddressPicturesDialog: false,
    failMessage: '',
    errorDialog: false
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      this.loadAddress();
      this.loadDefaults();
      this.dialogTab = 0;
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      });
    },
    cancel() {
      this.dialogOpen = false;
      this.resetValuesToDefault();
    },
    displayName() {
      if (this.isValidPerson) {
        return this.address.firstname + ' ' + this.address.lastname;
      } else if (this.isValidNonPerson) {
        return this.address.name;
      }
    },
    save() {
      if (this.dialogTab == 0) {
        //--> person
        this.savePerson();
      } else if (this.dialogTab == 1) {
        //--> non-person
        this.saveNonPerson();
      }
      this.resetValuesToDefault();
    },
    savePerson() {
      if (this.person.isPatient) {
        //map healthInsurance company to its key
        this.person.patient.healthInsuranceCompany = {
          lfdnr: this.person.patient.healthInsuranceCompany.key.lfdnr,
          firma: this.person.patient.healthInsuranceCompany.key.firma
        };
        if (this.person.isRelative) {
          this.person.patient.mainInsuranceData.healthInsuranceCompany = {
            lfdnr: this.person.patient.mainInsuranceData.healthInsuranceCompany.key.lfdnr,
            firma: this.person.patient.mainInsuranceData.healthInsuranceCompany.key.firma
          };
        }
        this.person.patient.svnr = parseInt(this.person.patient.svnr);
      }
      this.$store.dispatch('saveNewPerson', this.person).then(() => {
        this.resetValuesToDefault();
        this.dialogOpen = false;
      });
    },
    saveNonPerson() {
      this.$store.dispatch('saveNewNonPerson', this.nonPerson).then(() => {
        this.resetValuesToDefault();
        this.dialogOpen = false;
      });
    },
    resetValuesToDefault() {
      this.$store.commit('resetNewPerson');
      this.$store.commit('resetNewNonPerson');
    },
    setValuesToTestValues() {
      this.$store.commit('setValuesToTestValues');
    },
    isValidPerson() {
      return this.validAddress && (this.person.isPatient && this.validPatient || !this.person.isPatient) && (this.person.isRelative && this.validRelative || !this.person.isRelative) && (this.person.isEmployee && this.validEmployee || !this.person.isEmployee) && (this.person.isDoctor && this.validDoctor || !this.person.isDoctor) && (this.person.isConsumer || this.person.isAdultRepresentation || this.person.isPatient || this.person.isEmployee || this.person.isDoctor);
    },
    isValidNonPerson() {
      return (this.nonPerson.isCompany || this.nonPerson.isAdultRepresentation || this.nonPerson.isResidence) && this.validGeneralInfo && (this.nonPerson.isCompany && this.validCompany || !this.nonPerson.isCompany);
    },
    isValid() {
      if (this.dialogTab == 0) {
        return this.isValidPerson();
      } else if (this.dialogTab == 1) {
        return this.isValidNonPerson();
      }
    },
    loadDefaults() {
      this.$store.dispatch('loadAddAddressOptions');
    },
    gotoSearchPage() {
      this.$router.push({
        name: 'CRM'
      });
    },
    updateContactData(updatedAddress) {
      this.address.address.country = updatedAddress.country;
      this.address.address.countryName = updatedAddress.countryName;
      this.address.address.zip = updatedAddress.zip;
      this.address.address.city = updatedAddress.city;
      this.address.address.street = updatedAddress.streetName;
      this.address.tel = updatedAddress.tel;
      this.address.tel2 = updatedAddress.tel2;
      this.address.mobile = updatedAddress.mobile;
      this.address.email = updatedAddress.email;
    },
    closeAddressPicturesDialog() {
      this.showAddressPicturesDialog = false;
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    //load Address from Backend
    loadAddress() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response);
        if (response.status != 200) {
          this.addressExists = 'false';
        } else {
          this.addressExists = 'true';
        }
        this.address = response.data;
        this.loading = false;
        this.addressTypeName = this.address.addressTypes;

        /*this.addressTypeName.forEach(addressTypeNameElement => {
          this.adressTypeDetailedName.push(
            addressService.getAddressTypeDetailed(addressTypeNameElement).name
          )
        })*/

        // Use openstreetmap to get geocode from address

        geocoding.getGeocodeByAddress(this.address.address).then(response => {
          if (response.data.length == 0) {
            this.showMap = false;
            return;
          }
          this.mapsAddressObject.lat = parseFloat(response.data[0].lat);
          this.mapsAddressObject.lng = parseFloat(response.data[0].lon);
          this.showMap = true;
        });
      }).catch(error => {
        console.log(error);
      });
    },
    // open Google Maps and show the address
    openAddressInGoogleMaps() {
      window.open(`https://www.google.com/maps?q=${this.address.address.street},${this.address.address.zip},${this.address.address.city}`);
    },
    // open Google Maps and start Navigation right away
    openAddressInGoogleMapsWithNavigation() {
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.address.address.street},${this.address.address.zip} ${this.address.address.city}&dir_action=navigate`);
    },
    addressTypesIcons(item) {
      return addressService.getAddressTypeDetailed(item).icon;
    },
    addressTypesNames(item) {
      //this.adressTypeName.push(addressService.getAddressTypeDetailed(item).name)
      return addressService.getAddressTypeDetailed(item).name;
    }
  },
  computed: {
    allRoles() {
      const roles = [];
      if (this.address.patient !== null && this.address.patient !== undefined) {
        roles.push({
          name: 'Patient',
          icon: 'fas fa-user-injured'
        });
      }
      if (this.address.consumer == true) {
        roles.push({
          name: 'Konsument',
          icon: 'fas fa-user'
        });
      }
      if (this.address.healthInsuranceCompany !== undefined && this.address.healthInsuranceCompany !== null) {
        roles.push({
          name: 'Krankenkasse',
          icon: 'fas fa-hospital-user'
        });
      }
      if (this.address.doctor !== undefined && this.address.doctor !== null) {
        roles.push({
          name: 'Arzt',
          icon: 'fas fa-user-md'
        });
      }
      if (this.address.isAdultRepresentation == true) {
        roles.push({
          name: 'Erwachsenenverteter',
          icon: 'fas fa-handshake'
        });
      }
      if (this.address.supplier !== undefined && this.address.supplier !== null) {
        roles.push({
          name: 'Lieferant',
          icon: 'mdi-dolly'
        });
      }
      if (this.address.residence == true) {
        roles.push({
          name: 'Heim',
          icon: 'fas fa-home'
        });
      }
      if (this.address.company !== null && this.address.company !== undefined) {
        roles.push({
          name: 'Firma',
          icon: 'fas fa-building'
        });
      }
      if (this.address.employee !== null && this.address.employee !== undefined) {
        roles.push({
          name: 'Mitarbeiter',
          icon: 'mdi-cash-register'
        });
      }
      //todo: chip für interessen
      // if(this.address.interessent !== null && this.address.interessent !== undefined){
      // roles.push({name: 'interessent', icon: 'name-vom-icon'})
      // oder wenn es true / false geschickt wird:
      // if(this.address.interessent == true)
      // {
      // roles.push({name: 'Interessent', icon: 'name-vom-icon'})
      //}
      //}
      return roles;
    },
    telLink() {
      return 'tel:' + this.address.tel;
    },
    mailLink() {
      return 'mailto:' + this.address.email;
    },
    ...mapState({
      person: state => state.crmAddresses.newPerson,
      nonPerson: state => state.crmAddresses.newNonPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      validAddress: state => state.crmAddresses.validAddress,
      validPatient: state => state.crmAddresses.validPatient,
      validRelative: state => state.crmAddresses.validRelative,
      validEmployee: state => state.crmAddresses.validEmployee,
      validDoctor: state => state.crmAddresses.validDoctor,
      validGeneralInfo: state => state.crmAddresses.validGeneralInfo,
      validCompany: state => state.crmAddresses.validCompany
    })
  },
  watch: {
    '$route.params': 'init',
    tab() {
      const tab = this.tab;
      if (this.$route.query.tab != tab) this.$router.replace({
        query: {
          ...this.$route.query,
          tab
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};