import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, {
    attrs: {
      "elevation": "0"
    }
  }, [_c(VCardTitle, [_c(VTextField, {
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "label": "Suche",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('WarningDialog', {
    ref: "warning",
    on: {
      "warningActionPerformed": _vm.warningActionPerformed
    }
  }), _c('LBADialog', {
    attrs: {
      "width": 650,
      "dialogType": 'warning'
    },
    on: {
      "close": function ($event) {
        _vm.warningDialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Warnung")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VRow, {
          staticClass: "mx-2"
        }, [_c(VCol, {
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Wollen Sie diese Kundengruppe wirklich löschen? ")])], 1)];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          attrs: {
            "buttonType": 'error'
          },
          on: {
            "click": function ($event) {
              return _vm.deleteItemConfirm();
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v("Löschen")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.warningDialog,
      callback: function ($$v) {
        _vm.warningDialog = $$v;
      },
      expression: "warningDialog"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-0",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.nonEmptyCustomerGroups,
      "items-per-page": 10,
      "search": _vm.search,
      "item-key": "key.kdnr + key.lfdnr",
      "loading": _vm.loading,
      "sort-by": "order",
      "sort-desc": _vm.sortDesc,
      "mobile-breakpoint": "800"
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VToolbar, {
          attrs: {
            "flat": ""
          }
        }, [_c(VSpacer), _c('LBADialog', {
          attrs: {
            "persistent": true,
            "scrollable": true,
            "maxWidth": 500
          },
          on: {
            "close": function ($event) {
              return _vm.close();
            },
            "cancel": function ($event) {
              return _vm.cancel();
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              attrs,
              on
            }) {
              return [_c('LBAButton', _vm._g(_vm._b({
                attrs: {
                  "buttonType": 'primary',
                  "disabled": _vm.allCustomerGroupsUsed
                },
                on: {
                  "click": function ($event) {
                    ;
                    _vm.newCustomerGroup = true, _vm.show({});
                  }
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v("Neue Kd.-Gruppen-Zuordnung")];
                  },
                  proxy: true
                }], null, true)
              }, 'LBAButton', attrs, false), on))];
            }
          }, {
            key: "title",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.newCustomerGroup ? 'Kundengruppe zuordnen' : _vm.editDialogTitle))];
            },
            proxy: true
          }, {
            key: "content",
            fn: function () {
              return [_c(VRow, {
                staticClass: "py-4 mt-3"
              }, [_c(VCol, {
                staticClass: "pa-0 pr-3",
                attrs: {
                  "cols": "3"
                }
              }, [_c(VRow, {
                staticClass: "pt-3 d-flex justify-center"
              }, [_c('span', [_vm._v("Reihung")])]), _c(VRow, {
                staticClass: "d-flex justify-center"
              }, [_c('p', {
                staticClass: "mt-1 text-subtitle-1"
              }, [_vm._v(" " + _vm._s(_vm.activeCustomerGroup.order != null ? _vm.activeCustomerGroup.order + 1 : '-') + " ")])])], 1), _c(VCol, {
                staticClass: "pa-0 pr-1 pt-1",
                attrs: {
                  "cols": "9"
                }
              }, [_c(VSelect, {
                attrs: {
                  "label": "Kundengruppe",
                  "items": _vm.unusedAvailableCustomerGroups,
                  "item-text": "bezeichnung",
                  "outlined": "",
                  "return-object": ""
                },
                model: {
                  value: _vm.activeCustomerGroup.customerGroup,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeCustomerGroup, "customerGroup", $$v);
                  },
                  expression: "activeCustomerGroup.customerGroup"
                }
              })], 1)], 1)];
            },
            proxy: true
          }, {
            key: "success",
            fn: function () {
              return [_c('LBAButton', {
                attrs: {
                  "buttonType": 'success',
                  "disabled": _vm.activeCustomerGroup.customerGroup == null
                },
                on: {
                  "click": function ($event) {
                    _vm.newCustomerGroup === true ? _vm.save(_vm.activeCustomerGroup) : _vm.update(_vm.activeCustomerGroup);
                  }
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v("Speichern")];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.showCustomerGroup,
            callback: function ($$v) {
              _vm.showCustomerGroup = $$v;
            },
            expression: "showCustomerGroup"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "item.order",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.order != null ? item.order + 1 : '-') + " ")];
      }
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c('LBAButton', {
          attrs: {
            "buttonStyle": 'icon'
          },
          on: {
            "click": function ($event) {
              ;
              _vm.newCustomerGroup = false, _vm.show(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-pencil")])];
            },
            proxy: true
          }], null, true)
        }), _c('LBAButton', {
          attrs: {
            "buttonStyle": 'icon',
            "buttonType": 'error'
          },
          on: {
            "click": function ($event) {
              ;
              _vm.deleteCustomerGroup = item, _vm.warningDialog = true;
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-delete")])];
            },
            proxy: true
          }], null, true)
        })];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Kundengruppen zugeordnet ")];
      },
      proxy: true
    }])
  }), [_c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };