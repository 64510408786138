import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import { mapState } from 'vuex';
import axios from 'axios';
export default {
  data: () => ({
    headers: [{
      text: 'SM-ID',
      value: 'smId',
      align: 'left'
    }, {
      text: 'SM1',
      value: 'sm1',
      align: 'left'
    }, {
      text: 'SM2',
      value: 'sm2',
      align: 'left'
    }, {
      text: 'SM3',
      value: 'sm3',
      align: 'left'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }, {
      text: 'Menge',
      value: 'amount',
      align: 'end'
    }],
    loading: true,
    deliveryData: [],
    invoiceData: [],
    currencyFormatService,
    search: ''
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showInvoices: state => state.vkCustomerRevenueStatistic.showInvoices
    }),
    showInvoicesLocal: {
      get() {
        return this.showInvoices;
      },
      set(value) {
        this.$store.dispatch('setShowInvoices', value);
      }
    }
  },
  methods: {
    init() {
      this.loadArticleDetailsStatistics();
    },
    loadArticleDetailsStatistics() {
      if (this.articleCode !== '' && this.articleCode !== undefined) {
        this.loading = true;
        axios.get(`v1/statistics/customers/${this.lfdnr}/articleTypes/${this.articleTypeCode}/articleCategories/${this.articleCategoryCode}/article/${this.articleCode}`, {
          params: {
            from: this.filterFromDate,
            to: this.filterToDate
          }
        }).then(data => {
          this.deliveryData = data.data.deliveryData;
          this.invoiceData = data.data.invoiceData;
          this.loading = false;
        });
      }
    }
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    },
    articleTypeCode: {
      type: String,
      required: true
    },
    articleCategory: {
      type: String,
      required: true
    },
    articleCategoryCode: {
      type: String,
      required: true
    },
    article: {
      type: String,
      required: true
    },
    articleCode: {
      type: String,
      required: true
    }
  },
  watch: {
    filterFromDate: function () {
      this.loadArticleDetailsStatistics();
    },
    filterToDate: function () {
      this.loadArticleDetailsStatistics();
    },
    articleCategory: function () {
      this.init();
    },
    articleType: function () {
      this.init();
    },
    article: function () {
      this.init();
    }
  }
};