import { mapState } from 'vuex';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import WarningDialog from '@/components/core/WarningDialog.vue';
export default {
  components: {
    ErrorDialog,
    WarningDialog
  },
  props: {
    lfdnr: {
      required: true,
      type: Number
    }
  },
  data: () => ({
    search: null,
    deleteCustomerGroupDialog: false,
    sortDesc: false,
    loading: true,
    deleteCustomerGroup: null,
    newCustomerGroup: false,
    showCustomerGroup: false,
    warningDialog: false,
    warnMessage: 'Wollen Sie diese Kundengruppe wirklich löschen?',
    actions: [],
    colsPerButton: 0,
    additionalData: [],
    headers: [{
      text: 'Reihung',
      align: 'start',
      value: 'order'
    }, {
      text: 'Kundengruppe',
      value: 'customerGroup.bezeichnung'
    }, {
      text: 'Aktionen',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: '12em'
    }],
    activeCustomerGroup: [],
    oldCustomerGroup: null,
    editDialogTitle: '',
    failMessage: '',
    errorDialog: false
  }),
  computed: {
    ...mapState({
      customerGroups: state => state.crmCustomerGroups.customerGroups,
      availableCustomerGroups: state => state.crmCustomerGroups.availableCustomerGroups
    }),
    nonEmptyCustomerGroups() {
      return this.customerGroups.filter(cg => cg.customerGroup != null);
    },
    unusedAvailableCustomerGroups() {
      return this.availableCustomerGroups.filter(a => false == this.customerGroups.map(cg => cg.customerGroup).some(cg => {
        var _cg$key, _a$key, _cg$key2, _a$key2;
        return cg != null && cg.bezeichnung == a.bezeichnung && ((_cg$key = cg.key) === null || _cg$key === void 0 ? void 0 : _cg$key.code) == ((_a$key = a.key) === null || _a$key === void 0 ? void 0 : _a$key.code) && ((_cg$key2 = cg.key) === null || _cg$key2 === void 0 ? void 0 : _cg$key2.firma) == ((_a$key2 = a.key) === null || _a$key2 === void 0 ? void 0 : _a$key2.firma);
      })).sort((a, b) => a.bezeichnung.localeCompare(b.bezeichnung));
    },
    allCustomerGroupsUsed() {
      return this.unusedAvailableCustomerGroups.length == 0;
    }
  },
  methods: {
    init() {
      this.loadCustomerGroups();
      this.loadAvailableCustomerGroups();
    },
    async loadCustomerGroups() {
      await this.$store.dispatch('loadCustomerGroups', {
        lfdnr: this.lfdnr
      }).then(() => this.loading = false);
    },
    open(warnMessage, actions, additionalData = []) {
      console.log('open');
      this.warnMessage = warnMessage;
      this.actions = actions;
      this.warningDialog = true;
      this.colsPerButton = Math.floor(12 / actions.length);
      this.additionalData = additionalData;
    },
    warningActionPerformed(action) {
      this.$emit('warningActionPerformed', action);
      this.warningDialog = false;
    },
    loadAvailableCustomerGroups() {
      this.$store.dispatch('loadAvailableCustomerGroups');
    },
    deleteItemConfirm() {
      this.remove(this.deleteCustomerGroup);
      this.warningDialog = false;
      this.deleteCustomerGroup = null;
    },
    show(item) {
      if (this.newCustomerGroup) {
        const highestOrder = Math.max(...this.customerGroups.map(cg => cg.order));
        this.activeCustomerGroup = {
          order: highestOrder == -Infinity || isNaN(highestOrder) ? 0 : highestOrder + 1,
          lfdnr: this.lfdnr
        };
      } else {
        this.activeCustomerGroup = {
          ...item,
          customerGroup: null
        };
        this.oldCustomerGroup = {
          ...item
        };
        this.editDialogTitle = this.getEditDialogTitle(item);
      }
      this.showCustomerGroup = true;
    },
    save(toSave) {
      this.loading = true;
      this.$store.dispatch('saveCustomerGroup', {
        lfdnr: this.lfdnr,
        customerGroup: toSave
      }).then(() => {
        this.showCustomerGroup = false;
      }).catch(err => {
        console.error(err);
        this.failMessage = 'Während dem Speichern der Kundengruppenzuordnung ist ein Fehler aufgetreten!';
        this.errorDialog = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    update(toUpdate) {
      var _this$oldCustomerGrou;
      this.loading = true;
      this.$store.dispatch('updateCustomerGroupRelation', {
        lfdnr: this.lfdnr,
        oldCode: (_this$oldCustomerGrou = this.oldCustomerGroup) === null || _this$oldCustomerGrou === void 0 || (_this$oldCustomerGrou = _this$oldCustomerGrou.customerGroup) === null || _this$oldCustomerGrou === void 0 || (_this$oldCustomerGrou = _this$oldCustomerGrou.key) === null || _this$oldCustomerGrou === void 0 ? void 0 : _this$oldCustomerGrou.code,
        customerGroup: toUpdate
      }).then(() => {
        this.showCustomerGroup = false;
      }).catch(err => {
        console.error(err);
        this.failMessage = 'Während dem Update einer Kundengruppenzuordnung ist ein Fehler aufgetreten!';
        this.errorDialog = true;
      }).finally(this.loading = false);
    },
    cancel() {
      this.activeCustomerGroup = {
        ...this.oldCustomerGroup
      };
    },
    close() {
      this.showCustomerGroup = false;
    },
    remove(item) {
      this.$store.dispatch('removeCustomerGroupRelation', {
        lfdnr: this.lfdnr,
        customerGroup: item
      }).then(() => {
        this.loading = false;
        this.deleteCustomerGroupDialog = false;
      });
    },
    deleteCustomerGroupConformationMsg(item) {
      let customerGroupName = item.customerGroup.bezeichnung;
      if (customerGroupName >= 15) customerGroupName = customerGroupName.substring(0, 13) + '...';
      return `Wollen Sie die Kundengruppe "${customerGroupName}" wirklich löschen?`;
    },
    getEditDialogTitle(item) {
      let desc = item.customerGroup.bezeichnung;
      if (desc.length >= 15) desc = desc.substring(0, 13) + '...';
      return `Kundengruppe "${desc}" bearbeiten`;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    lfdnr() {
      this.init();
    }
  }
};