import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, [_c(VCardText, [_c(VRow, {
    staticClass: "my-2 d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VExpansionPanels, [_c('LBAFilterExpansion', {
    attrs: {
      "text-color": 'default'
    },
    on: {
      "clear": function ($event) {
        return _vm.resetFilter();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "12",
            "lg": "4",
            "xl": "4"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-lg-center align-center mx-sm-1 mt-n1"
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('span', {
          class: _vm.charged ? 'text-lg' : 'font-weight-bold text-lg'
        }, [_vm._v("Verrechnete Bewegungen")]), _c(VSwitch, {
          staticClass: "px-2",
          on: {
            "click": _vm.updateTable
          },
          model: {
            value: _vm.charged,
            callback: function ($$v) {
              _vm.charged = $$v;
            },
            expression: "charged"
          }
        }), _c('span', {
          class: _vm.charged ? 'font-weight-bold text-lg' : 'text-lg'
        }, [_vm._v("Gelieferte Bewegungen")])], 1)])]), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "4",
            "xl": "4"
          }
        }, [_c('LBADatepickerInput', {
          attrs: {
            "maximumDate": _vm.newToDate,
            "label": "Startdatum"
          },
          on: {
            "datePicked": _vm.updateFromDate,
            "input": _vm.updateFromDate
          },
          model: {
            value: _vm.newFromDate,
            callback: function ($$v) {
              _vm.newFromDate = $$v;
            },
            expression: "newFromDate"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "4",
            "xl": "4"
          }
        }, [_c('LBADatepickerInput', {
          attrs: {
            "minimumDate": _vm.newFromDate,
            "label": "Enddatum"
          },
          on: {
            "datePicked": _vm.updateToDate,
            "input": _vm.updateToDate
          },
          model: {
            value: _vm.newToDate,
            callback: function ($$v) {
              _vm.newToDate = $$v;
            },
            expression: "newToDate"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c(VDataTable, {
    staticClass: "elevation-0 mx-4",
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.articleMovements,
      "items-per-page": 10,
      "item-key": "wfcid",
      "sort-by": "date",
      "sort-desc": true,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VTextField, {
          staticStyle: {
            "margin-bottom": "25px"
          },
          attrs: {
            "prepend-inner-icon": "mdi-magnify",
            "label": "Suche",
            "outlined": "",
            "hide-details": "",
            "clearable": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }, {
      key: "item.docNr",
      fn: function ({
        item
      }) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'crmAddressDocumentDetailed',
              params: {
                lfdnr: _vm.lfdnr,
                wf_cid: item.wfCid
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.docNr) + " ")])];
      }
    }, {
      key: "item.artNr",
      fn: function ({
        item
      }) {
        return [_vm.permissionToUseArticleApp && item.artNr != undefined ? _c('router-link', {
          attrs: {
            "to": {
              name: 'Artikeldetails',
              params: {
                articleNumber: item.artNr
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.artNr) + " ")]) : _vm._e()];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };