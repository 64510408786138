import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, {
    attrs: {
      "elevation": "0"
    }
  }, [_c(VCardTitle, [_c(VTextField, {
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "label": "Suche",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('WarningDialog', {
    ref: "warning",
    on: {
      "warningActionPerformed": _vm.warningActionPerformed
    }
  }), _c('LBADialog', {
    attrs: {
      "width": 650,
      "dialogType": 'warning'
    },
    on: {
      "close": function ($event) {
        _vm.warningDialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Warnung")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm._v(_vm._s(_vm.warnMessage))];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('LBAButton', {
          attrs: {
            "buttonType": 'error'
          },
          on: {
            "click": function ($event) {
              return _vm.deleteItemConfirm();
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v("Löschen")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.warningDialog,
      callback: function ($$v) {
        _vm.warningDialog = $$v;
      },
      expression: "warningDialog"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-0",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.filteredContacts,
      "items-per-page": 10,
      "item-class": _vm.formatContactPersonActive,
      "search": _vm.search,
      "item-key": "key.lfdnr",
      "loading": _vm.loading,
      "sort-by": "nachname",
      "sort-desc": _vm.sortDesc,
      "mobile-breakpoint": "800",
      "show-expand": "",
      "single-expand": _vm.singleExpand,
      "expanded": _vm.expanded
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VToolbar, {
          attrs: {
            "flat": ""
          }
        }, [_c(VContainer, {
          staticClass: "fill-height",
          attrs: {
            "fluid": ""
          }
        }, [_c(VSwitch, {
          attrs: {
            "label": "Inaktive Ansprechpartner anzeigen"
          },
          model: {
            value: _vm.showInactiveContactPersons,
            callback: function ($$v) {
              _vm.showInactiveContactPersons = $$v;
            },
            expression: "showInactiveContactPersons"
          }
        })], 1), _c('LBADialog', {
          attrs: {
            "persistent": true,
            "scrollable": true,
            "maxWidth": 800
          },
          on: {
            "cancel": function ($event) {
              return _vm.cancel();
            },
            "close": function ($event) {
              _vm.showContact = false, _vm.closeAddingForm();
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c('LBAButton', {
                staticClass: "mb-2",
                attrs: {
                  "buttonType": 'primary',
                  "vbind": attrs,
                  "von": on
                },
                on: {
                  "click": _vm.showCreateContactPersonMenu
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v("Neue Kontaktperson")];
                  },
                  proxy: true
                }], null, true)
              })];
            }
          }, {
            key: "title",
            fn: function () {
              return [_c('h3', [_vm._v(_vm._s(_vm.cardTitle))])];
            },
            proxy: true
          }, {
            key: "content",
            fn: function () {
              return [_c(VForm, {
                ref: "contactPersonForm",
                model: {
                  value: _vm.formValid,
                  callback: function ($$v) {
                    _vm.formValid = $$v;
                  },
                  expression: "formValid"
                }
              }, [_c('h3', {
                staticClass: "mt-5 mb-4"
              }, [_vm._v("Persönliche Daten")]), _c(VRow, {
                attrs: {
                  "dense": ""
                }
              }, [_c(VCol, {
                attrs: {
                  "cols": "12",
                  "lg": "2"
                }
              }, [_c(VAutocomplete, {
                attrs: {
                  "outlined": "",
                  "label": "Titel",
                  "search-input": _vm.titel,
                  "items": _vm.titleSuggestions,
                  "auto-select-first": "",
                  "no-data-text": "Geben Sie einen Titel ein"
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.titel = $event;
                  },
                  "update:search-input": function ($event) {
                    _vm.titel = $event;
                  },
                  "keyup": function ($event) {
                    return _vm.autofillTitle();
                  }
                },
                model: {
                  value: _vm.activeContact.titel,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "titel", $$v);
                  },
                  expression: "activeContact.titel"
                }
              })], 1), _c(VCol, {
                attrs: {
                  "cols": "12",
                  "lg": "5"
                }
              }, [_c(VTextField, {
                attrs: {
                  "label": "Vorname",
                  "outlined": "",
                  "append-icon": "mdi-account"
                },
                on: {
                  "change": function ($event) {
                    return _vm.autofillGender();
                  }
                },
                model: {
                  value: _vm.activeContact.vorname,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "vorname", $$v);
                  },
                  expression: "activeContact.vorname"
                }
              })], 1), _c(VCol, {
                attrs: {
                  "cols": "12",
                  "lg": "5"
                }
              }, [_c(VTextField, {
                attrs: {
                  "label": "Nachname",
                  "outlined": "",
                  "append-icon": "mdi-account-outline"
                },
                model: {
                  value: _vm.activeContact.nachname,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "nachname", $$v);
                  },
                  expression: "activeContact.nachname"
                }
              })], 1)], 1), _c(VRow, {
                attrs: {
                  "dense": ""
                }
              }, [_c(VCol, {
                attrs: {
                  "cols": "12",
                  "lg": "4"
                }
              }, [_c(VSelect, {
                attrs: {
                  "label": "Geschlecht",
                  "items": _vm.genders,
                  "item-text": "bezeichnung",
                  "return-object": "",
                  "outlined": ""
                },
                model: {
                  value: _vm.activeContact.geschlecht,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "geschlecht", $$v);
                  },
                  expression: "activeContact.geschlecht"
                }
              })], 1), _c(VCol, {
                attrs: {
                  "cols": "12",
                  "lg": "4"
                }
              }, [_c(VAutocomplete, {
                attrs: {
                  "label": "Funktion",
                  "items": _vm.functions,
                  "item-text": "name",
                  "item-value": "lfdnr",
                  "counter": "255",
                  "maxlength": "255",
                  "filter": _vm.functionComboBoxFilter,
                  "return-object": "",
                  "outlined": "",
                  "clearable": "",
                  "multiple": "",
                  "search-input": _vm.functionSearchInput,
                  "auto-select-first": ""
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.functionSearchInput = $event;
                  },
                  "update:search-input": function ($event) {
                    _vm.functionSearchInput = $event;
                  },
                  "change": function ($event) {
                    _vm.functionSearchInput = '';
                  }
                },
                model: {
                  value: _vm.activeContact.funktionen,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "funktionen", $$v);
                  },
                  expression: "activeContact.funktionen"
                }
              })], 1), _c(VCol, {
                attrs: {
                  "cols": "12",
                  "lg": "4"
                }
              }, [_c(VAutocomplete, {
                attrs: {
                  "label": "Abteilung",
                  "items": _vm.departments,
                  "item-text": "name",
                  "item-value": "lfdnr",
                  "counter": "255",
                  "maxlength": "255",
                  "return-object": "",
                  "outlined": "",
                  "clearable": "",
                  "multiple": "",
                  "search-input": _vm.departmentSearchInput,
                  "auto-select-first": ""
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.departmentSearchInput = $event;
                  },
                  "update:search-input": function ($event) {
                    _vm.departmentSearchInput = $event;
                  },
                  "change": function ($event) {
                    _vm.departmentSearchInput = '';
                  }
                },
                model: {
                  value: _vm.activeContact.abteilungen,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "abteilungen", $$v);
                  },
                  expression: "activeContact.abteilungen"
                }
              })], 1)], 1), _c('h3', {
                staticClass: "mt-5 mb-3"
              }, [_vm._v("Kontaktdaten")]), _c(VRow, {
                attrs: {
                  "dense": ""
                }
              }, [_c(VCol, {
                attrs: {
                  "cols": "12",
                  "lg": "5"
                }
              }, [_c(VTextField, {
                attrs: {
                  "label": "Mobiltelefon",
                  "outlined": "",
                  "append-icon": "mdi-phone",
                  "rules": [_vm.validatePhoneNumber],
                  "error-messages": _vm.errorMessageTel
                },
                model: {
                  value: _vm.activeContact.tel_mobil,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "tel_mobil", $$v);
                  },
                  expression: "activeContact.tel_mobil"
                }
              })], 1), _c(VCol, {
                attrs: {
                  "cols": "12",
                  "lg": "5"
                }
              }, [_c(VTextField, {
                attrs: {
                  "label": "Telefon2",
                  "outlined": "",
                  "append-icon": "mdi-phone-outline",
                  "rules": [_vm.validatePhoneNumber2],
                  "error-messages": _vm.errorMessageTel2
                },
                model: {
                  value: _vm.activeContact.tel2,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "tel2", $$v);
                  },
                  expression: "activeContact.tel2"
                }
              })], 1), _c(VCol, {
                attrs: {
                  "cols": "12",
                  "lg": "2"
                }
              }, [_c(VTextField, {
                attrs: {
                  "label": "DW",
                  "outlined": ""
                },
                model: {
                  value: _vm.activeContact.dw,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "dw", $$v);
                  },
                  expression: "activeContact.dw"
                }
              })], 1)], 1), _c(VRow, {
                attrs: {
                  "dense": ""
                }
              }, [_c(VCol, {
                attrs: {
                  "cols": "12",
                  "sm": "6",
                  "lg": "6"
                }
              }, [_c(VTextField, {
                attrs: {
                  "label": "E-Mail",
                  "outlined": "",
                  "append-icon": "mdi-email",
                  "rules": [_vm.validateEmail],
                  "error-messages": _vm.errorMessageEmail
                },
                model: {
                  value: _vm.activeContact.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "email", $$v);
                  },
                  expression: "activeContact.email"
                }
              })], 1), _c(VCol, {
                attrs: {
                  "cols": "12",
                  "sm": "6",
                  "lg": "6"
                }
              }, [_c(VTextField, {
                attrs: {
                  "label": "Fax",
                  "outlined": "",
                  "append-icon": "mdi-fax"
                },
                model: {
                  value: _vm.activeContact.fax,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "fax", $$v);
                  },
                  expression: "activeContact.fax"
                }
              })], 1)], 1), _c(VRow, {
                attrs: {
                  "dense": ""
                }
              }, [_c(VCol, [_c(VTextarea, {
                attrs: {
                  "label": "Anmerkung",
                  "outlined": "",
                  "append-icon": "mdi-comment"
                },
                model: {
                  value: _vm.activeContact.anmerkung,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "anmerkung", $$v);
                  },
                  expression: "activeContact.anmerkung"
                }
              })], 1)], 1), _c(VRow, {
                attrs: {
                  "dense": ""
                }
              }, [_c('div', {
                staticClass: "d-flex flew-wrap"
              }, [_c(VCheckbox, {
                staticClass: "pr-4",
                attrs: {
                  "label": "Aktiv",
                  "outlined": ""
                },
                model: {
                  value: _vm.activeContact.active,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "active", $$v);
                  },
                  expression: "activeContact.active"
                }
              }), _c(VCheckbox, {
                staticClass: "pr-4",
                attrs: {
                  "label": "Hauptansprechpartner",
                  "outlined": ""
                },
                model: {
                  value: _vm.activeContact.hauptansprechpartner,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "hauptansprechpartner", $$v);
                  },
                  expression: "activeContact.hauptansprechpartner"
                }
              }), _c(VCheckbox, {
                attrs: {
                  "label": "Aussendung",
                  "outlined": ""
                },
                model: {
                  value: _vm.activeContact.aussendung,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeContact, "aussendung", $$v);
                  },
                  expression: "activeContact.aussendung"
                }
              })], 1)])], 1)];
            },
            proxy: true
          }, {
            key: "success",
            fn: function () {
              return [_vm.newContact ? _c('LBAButton', {
                attrs: {
                  "buttonType": 'success',
                  "disabled": !_vm.isFormValid()
                },
                on: {
                  "click": function ($event) {
                    return _vm.createContactPerson();
                  }
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v(" Speichern ")];
                  },
                  proxy: true
                }], null, false, 2586331753)
              }) : _c('LBAButton', {
                attrs: {
                  "buttonType": 'success'
                },
                on: {
                  "click": function ($event) {
                    return _vm.saveExistingContactPerson();
                  }
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v(" Speichern ")];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.showContact,
            callback: function ($$v) {
              _vm.showContact = $$v;
            },
            expression: "showContact"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "item.data-table-expand",
      fn: function ({
        item,
        expand,
        isExpanded
      }) {
        return [item.anmerkung ? _c('td', {
          staticClass: "text-start"
        }, [_c('LBAButton', {
          staticClass: "v-data-table__expand-icon",
          class: {
            'v-data-table__expand-icon--active': isExpanded
          },
          attrs: {
            "buttonStyle": 'icon'
          },
          on: {
            "click": function ($event) {
              return expand(!isExpanded);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, [_vm._v("mdi-chevron-down")])];
            },
            proxy: true
          }], null, true)
        })], 1) : _vm._e()];
      }
    }, {
      key: "expanded-item",
      fn: function ({
        headers,
        item
      }) {
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_vm._v(" " + _vm._s(item.anmerkung) + " ")])];
      }
    }, {
      key: "item.abteilungen",
      fn: function ({
        item
      }) {
        return _vm._l(item.abteilungen, function (department) {
          return _c(VChip, {
            key: department.id,
            staticClass: "ma-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(department.name) + " ")]);
        });
      }
    }, {
      key: "item.funktionen",
      fn: function ({
        item
      }) {
        return _vm._l(item.funktionen, function (funktion) {
          return _c(VChip, {
            key: funktion.id,
            staticClass: "ma-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(funktion.name) + " ")]);
        });
      }
    }, {
      key: "item.hauptansprechpartner",
      fn: function ({
        item
      }) {
        return [_c(VSimpleCheckbox, {
          attrs: {
            "disabled": ""
          },
          model: {
            value: item.hauptansprechpartner,
            callback: function ($$v) {
              _vm.$set(item, "hauptansprechpartner", $$v);
            },
            expression: "item.hauptansprechpartner"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c('LBAButton', {
          attrs: {
            "buttonStyle": 'icon'
          },
          on: {
            "click": function ($event) {
              return _vm.show(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-pencil")])];
            },
            proxy: true
          }], null, true)
        }), _c('LBAButton', {
          attrs: {
            "buttonStyle": 'icon',
            "buttonType": 'error'
          },
          on: {
            "click": function ($event) {
              ;
              _vm.deleteContactPerson = item, _vm.warningDialog = true;
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-delete")])];
            },
            proxy: true
          }], null, true)
        })];
      }
    }])
  }, [[_vm._v(" Keine Kontaktpersonen vorhanden ")]], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };