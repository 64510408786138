import axios from 'axios'

export default {
  async getArticleMovements(lfdnr, charged, from, to) {
    const response = await axios.get(
      `v1/crm/addresses/${lfdnr}/articlemovements?charged=${charged}${
        from != null ? `&from=${from}` : ''
      }${to != null ? `&to=${to}` : ''}`
    )

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  }
}
