import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, [_c(VCardTitle, [_c(VRow, {
    staticClass: "mb-4 d-flex align center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VExpansionPanels, [_c('LBAFilterExpansion', {
    staticClass: "mb-3",
    attrs: {
      "text-color": 'default'
    },
    on: {
      "clear": function ($event) {
        return _vm.resetFilter();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c('LBADatepickerInput', {
          attrs: {
            "label": 'Startdatum',
            "iconPosition": 'right'
          },
          model: {
            value: _vm.date1,
            callback: function ($$v) {
              _vm.date1 = $$v;
            },
            expression: "date1"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c('LBADatepickerInput', {
          attrs: {
            "label": 'Enddatum',
            "iconPosition": 'right'
          },
          model: {
            value: _vm.date2,
            callback: function ($$v) {
              _vm.date2 = $$v;
            },
            expression: "date2"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c(VAutocomplete, {
          staticClass: "mx-sm-1",
          attrs: {
            "label": "Dokumenttyp",
            "required": "",
            "items": _vm.filterableDocumentTypes,
            "item-text": "type",
            "outlined": "",
            "transition": "scale-transition",
            "origin": "center center",
            "clearable": "",
            "small-chips": "",
            "solo": "",
            "flat": ""
          },
          model: {
            value: _vm.selectedDocumentType,
            callback: function ($$v) {
              _vm.selectedDocumentType = $$v;
            },
            expression: "selectedDocumentType"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "12",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c(VAutocomplete, {
          staticClass: "mx-sm-1",
          attrs: {
            "label": "Status",
            "required": "",
            "outlined": "",
            "items": _vm.filterableDocumentStates,
            "item-text": "state",
            "transition": "scale-transition",
            "origin": "center center",
            "clearable": "",
            "small-chips": "",
            "solo": "",
            "flat": ""
          },
          model: {
            value: _vm.selectedStatus,
            callback: function ($$v) {
              _vm.selectedStatus = $$v;
            },
            expression: "selectedStatus"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c(VCardText, [_c(VTextField, {
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "label": "Suche (Dokument, Artikel)",
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VDataTable, {
    staticClass: "elevation-0",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.filteredData,
      "items-per-page": 10,
      "item-key": "name",
      "loading": _vm.loading,
      "sort-by": "date",
      "single-expand": true,
      "sort-desc": _vm.sortDesc,
      "show-expand": ""
    },
    on: {
      "update:expanded": _vm.openRow
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_vm.isUserBusinessAuthorized ? _c(VToolbar, {
          attrs: {
            "flat": ""
          }
        }, [_c(VSpacer), _c(VMenu, {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c('LBAButton', _vm._g(_vm._b({
                attrs: {
                  "buttonType": 'primary'
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v("Dokument erstellen")];
                  },
                  proxy: true
                }], null, true)
              }, 'LBAButton', attrs, false), on))];
            }
          }], null, false, 1608661246)
        }, [_c(VList, _vm._l(_vm.availableDocumentTypes, function (documentType) {
          return _c(VListItem, {
            key: documentType.id,
            attrs: {
              "to": {
                name: `Business`,
                query: {
                  address: _vm.lfdnr,
                  documentTypeId: documentType.id
                }
              }
            }
          }, [_c(VListItemTitle, [_vm._v(_vm._s(documentType.name))])], 1);
        }), 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.name",
      fn: function ({
        item
      }) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'crmAddressDocumentDetailed',
              params: {
                lfdnr: _vm.lfdnr,
                wf_cid: item.wf_cid
              }
            }
          }
        }, [_vm._l(_vm.items, function (item) {
          return _c('div', {
            key: item.id,
            on: {
              "click": function ($event) {
                return _vm.printDocument(item);
              }
            }
          });
        }), _vm._v(" " + _vm._s(item.name) + " ")], 2)];
      }
    }, {
      key: "expanded-item",
      fn: function ({
        item,
        headers
      }) {
        return [_c('td', {
          staticClass: "py-5",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('div', {
          staticClass: "d-flex justify-space-between flex-wrap"
        }, [_c('div', {
          staticClass: "my-4 col-12 col-md-5 col-lg-4 col-xl-3"
        }, [_c('h3', {
          staticClass: "text-center mb-3"
        }, [_vm._v("Abhängigkeiten")]), _c(VTreeview, {
          staticClass: "font-weight-medium no-select",
          attrs: {
            "item-key": "wf_cid",
            "hoverable": "",
            "activatable": "",
            "color": "primary",
            "active": _vm.selectedTreeViewArray,
            "items": _vm.treeViewItems,
            "open-all": ""
          },
          on: {
            "update:active": function ($event) {
              _vm.selectedTreeViewArray = $event;
            }
          },
          scopedSlots: _vm._u([{
            key: "prepend",
            fn: function ({
              item
            }) {
              return [item ? _c(VIcon, [_vm._v(" mdi-file-document-outline ")]) : _vm._e()];
            }
          }, {
            key: "append",
            fn: function ({}) {
              return undefined;
            }
          }], null, true)
        })], 1), _c(VDivider, {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.$vuetify.breakpoint.mdAndUp,
            expression: "$vuetify.breakpoint.mdAndUp"
          }],
          staticClass: "mt-6 mb-2",
          attrs: {
            "vertical": ""
          }
        }), _c('div', {
          staticClass: "col-12 col-md-7 col-xl-8 mt-4"
        }, [!_vm.selectedTreeViewItem ? _c('h3', {
          staticClass: "text-center mb-3"
        }, [_vm._v(" Enthaltene Artikel in "), _c('router-link', {
          attrs: {
            "to": {
              name: 'crmAddressDocumentDetailed',
              params: {
                lfdnr: _vm.lfdnr,
                wf_cid: _vm.treeViewItems[0].wf_cid
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.treeViewItems[0].name) + " ")])], 1) : _c('h3', {
          staticClass: "text-center mb-3"
        }, [_vm._v(" Enthaltene Artikel in "), _c('router-link', {
          attrs: {
            "to": {
              name: 'crmAddressDocumentDetailed',
              params: {
                lfdnr: _vm.lfdnr,
                wf_cid: _vm.selectedTreeViewItem.wf_cid
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedTreeViewItem.name) + " ")])], 1), _c(VDataTable, {
          staticClass: "mt-3",
          attrs: {
            "items": item.positions,
            "headers": _vm.positionHeaders,
            "items-per-page": 5,
            "item-key": "articleNr",
            "sort-by": "articleNr"
          },
          scopedSlots: _vm._u([{
            key: "item.sm",
            fn: function ({
              item
            }) {
              return [_vm._v(" " + _vm._s(item.sm == null ? '-' : item.sm) + " ")];
            }
          }, {
            key: "item.articleDescription",
            fn: function ({
              item
            }) {
              return [_vm._v(" " + _vm._s(item.articleDescription == null ? '-' : item.articleDescription) + " ")];
            }
          }, {
            key: "item.articleDescription2",
            fn: function ({
              item
            }) {
              return [_vm._v(" " + _vm._s(item.articleDescription2 == null ? '-' : item.articleDescription2) + " ")];
            }
          }, {
            key: "no-data",
            fn: function () {
              return [_vm._v(" Keine Artikel vorhanden ")];
            },
            proxy: true
          }], null, true)
        })], 1)], 1)])];
      }
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c('OpenLinkEyeComponent', {
          attrs: {
            "destination": {
              name: 'crmAddressDocumentDetailed',
              params: {
                lfdnr: _vm.lfdnr,
                wf_cid: item.wf_cid
              }
            },
            "newTab": false
          }
        }), _c(VBtn, {
          staticClass: "mr-2 col-xl-5",
          attrs: {
            "icon": "",
            "loading": item.printLoading
          },
          on: {
            "click": function ($event) {
              return _vm.printDocument(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-printer ")])], 1)];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Dokumente vorhanden ")];
      },
      proxy: true
    }])
  }), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };