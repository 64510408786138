import ArticleMovementsService from '@/services/crm/articleMovementsService.js';
import LBAFilterExpansion from '@/components/ui/expansion/LBAFilterExpansion.vue';
import LBADatepickerInput from '@/components/ui/inputs/LBADatepickerInput.vue';
export default {
  components: {
    LBAFilterExpansion,
    LBADatepickerInput
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      charged: false,
      fromDate: null,
      newFromDate: null,
      newToDate: null,
      toDate: null,
      tableHeaders: [{
        text: 'Dokument',
        value: 'docNr',
        align: 'start'
      }, {
        text: 'Datum',
        value: 'date'
      }, {
        text: 'Lieferdatum',
        value: 'deliveryDate'
      }, {
        text: 'Art. Nr.',
        value: 'artNr'
      }, {
        text: 'Artikel',
        value: 'artName'
      }, {
        text: 'SM',
        value: 'sm'
      }, {
        text: 'Liefermenge',
        value: 'deliveryQuantity'
      }, {
        text: 'Verrechnungsmenge',
        value: 'invoiceQuantity'
      }, {
        text: 'ME',
        value: 'unit'
      }],
      articleMovements: [],
      search: ''
    };
  },
  methods: {
    async resetFilter() {
      this.charged = false;
      this.fromDate = null;
      this.newFromDate = null;
      this.toDate = null;
      this.newToDate = null;
      await this.updateTable();
    },
    async updateFromDate(newFromDate) {
      console.log(newFromDate);
      if (this.fromDate != newFromDate) {
        this.fromDate = newFromDate;
        await this.updateTable();
      }
    },
    async updateToDate(newToDate) {
      if (this.toDate != newToDate) {
        this.toDate = newToDate;
        await this.updateTable();
      }
    },
    async updateTable() {
      const tableData = await ArticleMovementsService.getArticleMovements(this.lfdnr, this.charged, this.fromDate, this.toDate);
      this.articleMovements = tableData;
    }
  },
  computed: {
    permissionToUseArticleApp() {
      return this.$store.getters.userHasAppById(7);
    }
  },
  async mounted() {
    await this.updateTable();
  }
};