import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "grey--text text--darken-2 mb-5"
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.articleType) + " -> " + _vm._s(_vm.articleCategory) + " -> " + _vm._s(_vm.article) + " -> Details ")])]), _c('div', {
    staticClass: "mx-auto mb-12"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('h4', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("Rechnungsdokumente")]), _c(VSwitch, {
    staticClass: "mx-4 mt-n1",
    model: {
      value: _vm.showInvoicesLocal,
      callback: function ($$v) {
        _vm.showInvoicesLocal = $$v;
      },
      expression: "showInvoicesLocal"
    }
  }), _c('h4', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("Lieferdokumente")])], 1), _c(VDataTable, {
    staticClass: "datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.showInvoices === true ? _vm.deliveryData : _vm.invoiceData,
      "loading": _vm.loading,
      "search": _vm.search,
      "mobile-breakpoint": "800",
      "loading-text": "Daten werden geladen..."
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VTextField, {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Suche",
            "outlined": "",
            "hide-details": "",
            "dense": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }, {
      key: "item.revenue",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.currencyFormatService.formatEuro(item.revenue)) + " ")];
      }
    }, {
      key: "item.amount",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.amount.toLocaleString('de-DE')) + " " + _vm._s(item.unit) + " ")];
      }
    }])
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };